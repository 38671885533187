<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { TYPES as LANGUAGE_TYPES } from './store'
import { ROUTES as LANGUAGE_ROUTES } from './router'
import { hasPerm } from '@/components/PermissionHelper'

import ThuxListMixin from '../../../components/thux-list/ThuxListMixin'

import LanguageTable from './LanguageTable'
import LanguageCommonFilters from './LanguageCommonFilters'
import LanguageEdit from './LanguageEdit.vue'

export default {
  name: 'LanguageList',
  mixins: [ThuxListMixin],
  components: {
    'component-table': LanguageTable,
    'common-filters': LanguageCommonFilters,
    'component-edit': LanguageEdit,
    'component-detail': LanguageEdit
  },
  data () {
    return {
      LANGUAGE_ROUTES,
      title: this.$t('Languages'),
      rolePerm: ['i18n_language_list'],
      actionEnablePermission: ['i18n_language_enable'],
      actionDisablePermission: ['i18n_language_disable'],
      fieldName: 'code',
      searchFields: [
        { key: 'filter__code__icontains', placeholder: this.$t('Code'), type: 'text', col: 3 },
        { key: 'filter__name__icontains', placeholder: this.$t('Name'), type: 'text', col: 3 },
        { key: 'filter__country__icontains', placeholder: this.$t('Country'), type: 'text', col: 3 },
        { key: 'filter__nationality__icontains', placeholder: this.$t('Nationality'), type: 'text', col: 3 }
      ]
    }
  },
  computed: {
    ...mapGetters({
      ...LANGUAGE_TYPES.GENERIC.i18n.language.LIST.GETTERS
    }),
    showList () {
      return this.$route.name === this.LANGUAGE_ROUTES.LANGUAGE_LIST
    },
    canSeeActionSelect () {
      return true
    },
    canAddInstance () {
      return hasPerm('i18n_language_create')
    }
  },
  methods: {
    ...mapMutations({
      setSelectedList: LANGUAGE_TYPES.GENERIC.i18n.language.LIST.MUTATIONS.setSelectedList,
      setSelectAll: LANGUAGE_TYPES.GENERIC.i18n.language.LIST.MUTATIONS.setSelectAll
    }),
    ...mapActions({
      ...LANGUAGE_TYPES.GENERIC.i18n.language.LIST.ACTIONS
    })
  }
}
</script>
