<template>
  <panel :title="title" noButton="true" v-has-perms="rolePerm">
    <div>
      <b-form>
        <b-form-row>
          <form-thux-horizontal-input
            v-if="!id"
            :validator="$v.form.code"
            :external-errors="errors['code']"
            :label-form="'Code' | translate"
            class-form="col-12 mb-2"
            :value="form.code"
            :disabled="!formEditable"
            @change="$set(form, 'code', $event)"
          />
        </b-form-row>
        <b-form-row>
          <form-thux-horizontal-input
            :validator="$v.form.name"
            :external-errors="errors['name']"
            :label-form="'Name' | translate"
            class-form="col-12 mb-2"
            type="text"
            :value="form.name"
            :disabled="!formEditable"
            @change="$set(form, 'name', $event)"
          />
        </b-form-row>
        <b-form-row>
          <form-thux-horizontal-input
            :validator="$v.form.country"
            :external-errors="errors['country']"
            :label-form="'Country' | translate"
            class-form="col-12 mb-2"
            type="text"
            :value="form.country"
            :disabled="!formEditable"
            @change="$set(form, 'country', $event)"
          />
        </b-form-row>
        <b-form-row>
          <form-thux-horizontal-input
            :validator="$v.form.nationality"
            :external-errors="errors['nationality']"
            :label-form="'Nationality' | translate"
            class-form="col-12 mb-2"
            type="text"
            :value="form.nationality"
            :disabled="!formEditable"
            @change="$set(form, 'nationality', $event)"
          />
        </b-form-row>
      </b-form>
      <div class="mt-3 f-w-600 d-flex justify-content-end">
        <b-button
          class="mr-3"
          variant="default"
          @click.prevent="formEditable ? cancel() : closeForm()">
          {{ formEditable ? 'Cancel' : 'Close' | translate }}
        </b-button>
        <b-button
          variant="primary"
          v-has-perms="editRolePerm"
          @click.prevent="formEditable ? onSubmit() : makeFormEditable()"
          :disabled="$v.form.$invalid">
          {{ formEditable ? 'Save' : 'Edit' | translate }}
        </b-button>
      </div>
    </div>
  </panel>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import { mapActions, mapGetters } from 'vuex'
import { TYPES as LANGUAGE_TYPES } from './store'

import ThuxDetailPanelMixin from '../../../components/thux-detail-panel/ThuxDetailPanelMixin'

export default {
  name: 'LanguageEdit',
  mixins: [ThuxDetailPanelMixin],
  data () {
    return {
      pageName: 'Language',
      rolePerm: 'i18n_language_retrieve',
      editRolePerm: 'i18n_language_update'
    }
  },
  computed: {
    ...mapGetters({
      ...LANGUAGE_TYPES.GENERIC.i18n.language.DETAIL.GETTERS
    })
  },
  components: { },
  methods: {
    ...mapActions({
      ...LANGUAGE_TYPES.GENERIC.i18n.language.DETAIL.ACTIONS
    }),
    getFormData () {
      const formData = Object.assign({}, this.form)
      formData.id = formData.code
      return formData
    }
  },
  validations: {
    form: {
      code: { required },
      name: { required },
      country: { required },
      nationality: { required }
    }
  }
}
</script>
